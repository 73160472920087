<template>
  <v-container>
    <div v-if="checkIfPermission('view promocode', admin.permissions)">
      <v-dialog v-model="createDialog" max-width="600">
        <v-card>
          <v-card-title>
            <span class="headline"
              >{{ isEditing ? 'Update' : 'Create' }} Promo Code</span
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.title"
                    :rules="[rules.required]"
                    label="Title"
                    filled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.code"
                    :rules="[rules.required]"
                    append-icon="mdi-refresh"
                    label="Code"
                    filled
                    required
                    @click:append="generateRandom6DigitCode"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="newPromoCode.applicableToAllProducts"
                    label="Applicable to all Product?"
                    filled
                    item-text="text"
                    item-value="value"
                    :items="[
                      { text: 'Yes', value: 'true' },
                      { text: 'No', value: 'false' }
                    ]"
                  >
                    >
                  </v-select>
                </v-col>
                <v-col
                  v-if="
                    newPromoCode.applicableToAllProducts === 'false' ||
                    newPromoCode.applicableToAllProducts === false
                  "
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="newPromoCode.attachedGiftcards"
                    label="Giftcard"
                    filled
                    item-text="name"
                    item-value="_id"
                    :items="giftcards"
                    multiple
                    chips
                  >
                    >
                  </v-autocomplete>
                </v-col>
                <v-col
                  v-if="
                    newPromoCode.applicableToAllProducts === 'false' ||
                    newPromoCode.applicableToAllProducts === false
                  "
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="newPromoCode.attachedCrypto"
                    label="Crypto"
                    filled
                    item-text="name"
                    item-value="_id"
                    :items="crypto"
                    multiple
                    chips
                  >
                    >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newPromoCode.discountType"
                    label="Discount Type"
                    filled
                    :rules="[rules.required]"
                    item-text="text"
                    item-value="value"
                    :items="[
                      { text: 'Percentage', value: 'percentage' },
                      { text: 'Fixed Amount', value: 'fixed' }
                    ]"
                  >
                    >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.discountValue"
                    :rules="[rules.required]"
                    label="Discount Value"
                    filled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newPromoCode.minPriceApplicable"
                    label="Minimum Price Applicable?"
                    filled
                    item-text="text"
                    item-value="value"
                    :items="[
                      { text: 'Yes', value: 'true' },
                      { text: 'No', value: 'false' }
                    ]"
                  >
                    >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.minPrice"
                    label="Minimum Price"
                    filled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newPromoCode.limitByUserCount"
                    label="Limit by User count?"
                    filled
                    item-text="text"
                    item-value="value"
                    :items="[
                      { text: 'Yes', value: 'true' },
                      { text: 'No', value: 'false' }
                    ]"
                  >
                    >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.maxUserCount"
                    label="Max User Count"
                    filled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="newPromoCode.limitByOverallCount"
                    label="Limit by Overall count?"
                    filled
                    item-text="text"
                    item-value="value"
                    :items="[
                      { text: 'Yes', value: 'true' },
                      { text: 'No', value: 'false' }
                    ]"
                  >
                    >
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.overallCount"
                    label="Overall Count"
                    filled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.startDate"
                    label="Start Date"
                    :rules="[rules.required]"
                    type="date"
                    filled
                  >
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.endDate"
                    label="End Date"
                    :rules="[rules.required]"
                    type="date"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.startTime"
                    label="Start Time"
                    :rules="[rules.required]"
                    type="time"
                    filled
                  >
                    >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="newPromoCode.endTime"
                    label="End Time"
                    :rules="[rules.required]"
                    type="time"
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="newPromoCode.status"
                    label="Activate ?"
                    :rules="[rules.required]"
                    filled
                    item-text="text"
                    item-value="value"
                    :items="[
                      { text: 'Yes', value: 'active' },
                      { text: 'No', value: 'inactive' }
                    ]"
                  >
                    >
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="createDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              @click="createPromoCode"
              :loading="isCreating"
              >{{ isEditing ? 'Update' : 'Create' }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <h1 class="mb-5">Promo Codes</h1>
      <v-card flat>
        <v-container>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-btn
              v-if="checkIfPermission('create promocode', admin.permissions)"
              @click="createDialog = true"
              color="primary"
              depressed
            >
              <v-icon left>mdi-plus</v-icon>
              Create Promo Code</v-btn
            >
          </v-toolbar>
        </v-container>
        <v-card-text>
          <v-data-table
            :items="promoCodes"
            :headers="tableheader"
            :loading="loadingCodes"
            :items-per-page="perPage"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 15, 25, 50, 100]
            }"
            @update:items-per-page="updateItemPerPage"
            @update:page="updatePage"
            :server-items-length="apiData.total"
          >
            <template v-slot:[`item.value`]="{ item }">
              {{ item.discountValue }}
            </template>
            <template v-slot:[`item.type`]="{ item }">
              {{ item.discountType }}
            </template>
            <template v-slot:[`item.duration`]="{ item }">
              {{ new Date(item.startDate).toDateString() }},
              {{ item.startTime }} -
              {{ new Date(item.endDate).toDateString() }},
              {{ item.endTime }}
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              {{ new Date(item.startDate).toDateString() }},
              {{ item.startTime }}
            </template>
            <template v-slot:[`item.expiryDate`]="{ item }">
              {{ new Date(item.endDate).toDateString() }},
              {{ item.endTime }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <span
                :class="`d-block text-center text-capitalize rounded py-2 px-3 white--text ${
                  item.status == 'active' ? 'success' : 'error'
                }`"
                >{{ item.status == 'active' ? 'Active' : 'Inactive' }}</span
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu
                bottom
                left
                v-if="checkIfPermission('manage promocode', admin.permissions)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="selectedPromoCode(item)" ripple>
                    <v-list-item-title>View</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="actionOnPromoCode(item, 'inactive')"
                    v-if="item.status === 'active'"
                    ripple
                  >
                    <v-list-item-title>Deactivate</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="actionOnPromoCode(item, 'active')"
                    v-if="item.status === 'inactive'"
                    ripple
                  >
                    <v-list-item-title>Activate</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item @click="deletePromoCode(item)" ripple>
                  <v-list-item-title class="text--error"
                    >Delete</v-list-item-title
                  >
                </v-list-item> -->
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <h1 class="error--text">You don't have access to this page</h1>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import promoCodesApi from '../../../api/promo-codes'
import dataApi from '../../../api/systemData'

const tableheader = [
  {
    value: 'title',
    text: 'Title'
  },
  {
    value: 'code',
    text: 'Promotion Code'
  },
  {
    value: 'type',
    text: 'Type'
  },
  {
    value: 'value',
    text: 'Value'
  },
  {
    value: 'usageCount',
    text: 'Uses'
  },
  {
    value: 'startDate',
    text: 'Start Date'
  },
  {
    value: 'expiryDate',
    text: 'Expiry Date'
  },
  {
    value: 'status',
    text: 'Status'
  },
  {
    value: 'action',
    text: '',
    sortable: false
  }
]

export default {
  name: 'PromoCodes',
  data() {
    return {
      filterDialog: false,
      tableheader,
      createDialog: false,
      giftcards: [],
      crypto: [],
      promoCodes: [],
      isCreating: false,
      loadingCodes: false,
      isEditing: false,
      filterDate: {
        from: '',
        to: ''
      },
      newPromoCode: {
        code: ''
      },
      perPage: 10,
      apiData: {},
      lastPage: 1,
      rules: {
        required: (value) => !!value || 'Required',
        number: (value) =>
          (!!value && /^[0-9]+$/.test(value)) || 'Must be a number'
      }
    }
  },
  watch: {
    createDialog(val) {
      if (!val) {
        this.newPromoCode = {
          code: '',
          discount: ''
        }
        this.isEditing = false
      } else {
        if (!this.isEditing) {
          this.generateRandom6DigitCode()
        }
      }
    }
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.admin
    })
  },
  created() {
    this.getPromoCodes(0)
    this.getGiftCards()
    this.getSystemCurrencies()
  },
  methods: {
    async selectedPromoCode(item) {
      this.isEditing = true
      this.newPromoCode = item
      this.newPromoCode.applicableToAllProducts = this.newPromoCode.applicableToAllProducts.toString()
      this.newPromoCode.minPriceApplicable = this.newPromoCode.minPriceApplicable?.toString()
      this.newPromoCode.limitByUserCount = this.newPromoCode.limitByUserCount?.toString()
      this.newPromoCode.limitByOverallCount = this.newPromoCode.limitByOverallCount?.toString()
      this.createDialog = true
    },
    async createPromoCode() {
      if (!this.$refs.form.validate()) return
      if (this.isEditing) {
        this.updatePromoCode()
        return
      }
      this.isCreating = true
      const res = await promoCodesApi.promoCodes().createCode(this.newPromoCode)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'PromoCode created successfully',
        status: true
      })
      this.isCreating = false
      this.createDialog = false
      this.getPromoCodes(0)
    },
    async updatePromoCode() {
      if (!this.$refs.form.validate()) return
      this.isCreating = true
      const res = await promoCodesApi.promoCodes().updateCode(this.newPromoCode)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        this.isCreating = false
        return
      }
      this.$store.dispatch('alert', {
        message: 'PromoCode updated successfully',
        status: true
      })

      this.newPromoCode = {
        code: '',
        discount: ''
      }
      this.createDialog = false
      this.isCreating = false
    },
    updatePage(page) {
      if (this.lastPage - page > 2) {
        page = this.lastPage
      }
      this.lastPage = page
      this.getPromoCodes((page - 1) * this.perPage)
    },
    updateItemPerPage(count) {
      this.perPage = count
      this.getPromoCodes((this.lastPage - 1) * this.perPage)
    },
    async getPromoCodes(length) {
      this.loadingCodes = true

      const param = {
        populate: ['attachedCrypto', 'attachedGiftcards'],
        limit: this.perPage,
        offset: length,
        orderBy: ['status', 'createdAt'],
        order: ['asc', 'desc']
      }

      const res = await promoCodesApi.promoCodes().getCodes({
        requester: 'admin',
        param
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }

      this.promoCodes = res.data.data.data
      this.apiData = res.data.data.meta
      this.filterDialog = false
      this.loadingCodes = false
    },
    async deletePromoCode(item) {
      this.loadingCodes = true
      const res = await promoCodesApi.promoCodes().deleteCode(item)
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'PromoCode deleted successfully',
        status: true
      })
      this.loadingCodes = false
      this.getPromoCodes(0)
    },
    async actionOnPromoCode(item, status) {
      this.loadingCodes = true
      const res = await promoCodesApi.promoCodes().updateCode({
        id: item._id,
        status
      })
      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })
        return
      }
      this.$store.dispatch('alert', {
        message: 'PromoCode updated successfully',
        status: true
      })
      this.loadingCodes = false
      this.getPromoCodes(0)
    },
    generateRandom6DigitCode() {
      var text = ''
      var possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (var i = 0; i < 6; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      this.newPromoCode.code = text
    },
    async getGiftCards() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'giftcard', params: { status: true } })
      this.giftcards = res.data.data.data
    },
    async getSystemCurrencies() {
      const res = await dataApi
        .data()
        .getData('admin', { type: 'currency', params: { status: true } })
      this.crypto = res.data.data.data
    }
  }
}
</script>
